<template>
  <div class="copyright">
    <div class="copy">
      <div v-html="copyRightData.copyright"></div>
    </div>
    <div style="margin-top: 3.333vw">
      <a href="javascript:;" v-html="copyRightData.record">
      </a>
    </div>
  </div>
</template>
<script>
import {getMeta} from "@/api/api";
export default {
  data(){
    return{
      copyRightData:{
        copyright:'',
        record:''
      }
    }
    },
  mounted() {
    this.togetMeta()
  },
  methods:{
    togetMeta(){
      // 模拟接口获取数据
      let _this=this
      let now=Date.now()
      let dataCopyRight =JSON.parse(window.localStorage.getItem('dataCopyRight'))
      let expireds_copyRight =window.localStorage.getItem('expireds_copyRight')
      console.log(dataCopyRight)
      console.log(now)
      if (dataCopyRight == null || now >= expireds_copyRight) {
        window.localStorage.removeItem('dataCopyRight');
        window.localStorage.removeItem('expireds_copyRight');
        getMeta()
            .then( r => {
              // let res=r.data.data
              let res = r.data;
              if (res.status != 1) {//获取目录列表失败，是则直接页面提示
                _this.$message.error('获取版权信息失败')
                return;
              }else{
                // 获取列表成功
                console.log(r.data)
                _this.copyRightData.copyright=res.data.copyright
                _this.copyRightData.record=res.data.record
                let copyRight=[res.data.copyright,res.data.record]
                window.localStorage.setItem('dataCopyRight',JSON.stringify(copyRight))
                window.localStorage.setItem('expireds_copyRight',now+1000*60*60*24)
                console.log('发起copyRight请求')
                }
            })
      }else{
        console.log('未发起copyRight请求')
        _this.copyRightData.copyright=dataCopyRight[0]
        _this.copyRightData.record=dataCopyRight[1]
      }
    }
  }

}
</script>

<style lang="less" scoped>
.copyright {
  width: 100%;
  padding-bottom:5.555vw;
  z-index: 1;
  font-size: 2.777vw;
  line-height: 4.888vw;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  a{
    color: rgba(255, 255, 255, 0.4)!important;
  }
}
</style>
