<template>
  <div id="nav">
    <div class="logo fl">
      <img src="../assets/images/main_logo.png" alt="微光logo">
    </div>

    <div class="menu fr">
      <div class="menu_icon" >
        <input type="checkbox" id="hbbMenu">
        <label for="hbbMenu" class="hbbIcon" @click="menuShow">
          <div class="hbbMenu"></div>
        </label>

      </div>

    </div>

    <div v-show="isMenuShow" class="MenuList" :class="{animated:isMenuShow, fadeInRight:isMenuShow}" style="display:block; animation-duration: 0.5s;" >
      <div style="min-height: calc(100vh - 31.165vw);">
        <treeMenu :list="menuList" ></treeMenu>

      </div>
      <div class="langBox">
        <a href="https://www.vivolight.cn/dist_en">English</a><span style="display: inline-block;padding: 3px 5px;color:#fff;">|</span><a href="https://www.vivolight.cn/dist">中文</a>
      </div>
    </div>



  </div>

</template>
<script>
import {getMenu} from '@/api/api'
import treeMenu from "@/component/treeMenu";

export default {
  data() {
    return {
      /*目录图表展示*/
      isMenuShow: false,
      /*菜单列表*/
      menuList: [],
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    //监听滚动事件
    // window.addEventListener('scroll', this.menuScroll)
    // 获取menu数据
    this.togetMenu()
  },
  methods: {
    /*获取菜单列表*/
    togetMenu() {
      let _this = this
      let now=Date.now()
      let dataMenu =JSON.parse(window.localStorage.getItem('dataMenu'))
      let expireds_menu =window.localStorage.getItem('expireds_menu')
      console.log(dataMenu)
      console.log(now)
      if (dataMenu == null || now >= expireds_menu) {
        window.localStorage.removeItem('dataMenu');
        window.localStorage.removeItem('expireds_menu');
        getMenu()
            .then(r => {
              let res = r.data;
              if (res.status != 1) {//获取目录列表失败，是则直接页面提示
                _this.$message.error('获取目录列表失败')
                return;
              }else{
                // 获取列表成功
                let apidataList = res.data
                for (let i = 0; i < apidataList.length; i++) {
                  let menuData = apidataList[i]
                  menuData.isRoot = true
                  _this.handleMenuData(menuData)
                }
                console.log(apidataList)
                _this.menuList = apidataList
                window.localStorage.setItem('dataMenu',JSON.stringify(apidataList))
                window.localStorage.setItem('expireds_menu',now+1000*60*60*24)
                console.log('发起菜单请求')
              }
            })
      }else{
        console.log('未发起请求')
        _this.menuList = dataMenu
      }
    },


    /**
     * 大前提：
     * 1.sub和subMenu和product互斥
     * 2.subMenu不会有sub,因为要用同级subNav填充
     * 3.subNav下面的cate里面有product（类似sub）
     */
    /*处理菜单接口某一节点数据*/
    handleMenuData(data) {
      let _this = this;

      //默认激活状态
      data.active = false

      //处理异构数据
      if (data.subMenu && data.subMenu.length > 0) {//存在subMenu
        data.sub = data.subMenu
        if (data.subNav) {//subNav也在
          for (let i = 0; i < data.sub.length; i++) {
            if (data.subNav[i] && data.subNav[i].cate && data.subNav[i].cate.length > 0) {
              data.sub[i].sub = data.subNav[i].cate
            }
          }
        }
      }

      if (data.product && data.product.length > 0) {//存在product的时候
        data.sub = data.product
      }

      if (data.sub && data.sub.length > 0) {
        for (let i = 0; i < data.sub.length; i++) {
          let subdata = data.sub[i]
          _this.handleMenuData(subdata)
        }
      }

    },
    /*点击菜单显示隐藏*/
    menuShow(){
      this.isMenuShow=!this.isMenuShow
    }
  },
  components:{
    treeMenu
  }
}
</script>
<style>
.MenuList{
  /*animate-duration: 500ms;*/
}
</style>
<style lang="less" scoped>

/*汉堡包*/


input#hbbMenu {
  display: none;
}

.hbbIcon {
  display: block;
  width: 5.555vw;
  height: 5.555vw;
  padding-top: 3vw;
}

label.hbbIcon {
  position: fixed;
  z-index: 200;
}

.hbbIcon .hbbMenu {
  background: #fff;
  content: '';
  display: block;
  height: 0.5556vw;
  position: absolute;
  transition: background ease .3s, top ease .3s .3s, transform ease .3s;
  width: 4.4444vw;
}
.hbbIcon .hbbMenu::before,.hbbIcon .hbbMenu::after {
  background: #fff;
  content: '';
  display: block;
  height: 0.5556vw;
  position: absolute;
  transition: background ease .1s, top ease .1s .1s, transform ease .1s;
  width: 5.5556vw;
}


.hbbIcon .hbbMenu::before {
  top: -1.6667vw;
}

.hbbIcon .hbbMenu::after {
  top: 1.6667vw;
}

#hbbMenu:checked + .hbbIcon .hbbMenu {
  background: transparent;
}

#hbbMenu:checked + .hbbIcon .hbbMenu::before {
  transform: rotate(45deg);
}

#hbbMenu:checked + .hbbIcon .hbbMenu::after {
  transform: rotate(-45deg);
}

#hbbMenu:checked + .hbbIcon .hbbMenu::before,
#hbbMenu:checked + .hbbIcon .hbbMenu::after {
  top: 0;
  transition: top ease .3s, transform ease .3s .3s;
}

/*汉堡包 END*/



#nav {
  position: fixed;
  top: 0;
  font-size: 4.444vw;
  width: 100vw;
  height: 13.611vw;
  background: rgba(16, 60, 104, 0.7);
  backdrop-filter: blur(1.388vw);
  padding: 3.75vw 6.666vw 0 6.666vw;
  box-sizing: border-box;

  img {
    width: 29.166vw;
    height: 6.25vw;
  }

  .menu {
    width: 5.555vw;
    height: 5.555vw;

    img {
      width: 100%;
      height: 100%;
    }
    }

  .MenuList {
    padding:2.777vw 0 2.777vw 0;
    height: calc(100vh - 13.611vw);
    width: 87.916vw;
    overflow-y:scroll;
    position: absolute;
    top: 13.611vw;
    right: 0;
    transition: all 1s;
    backdrop-filter: blur(2.777vw);
    background: rgba(7, 25, 56, 0.93);
    box-sizing: border-box;
    .langBox{
      margin-left: 50%;
      line-height: 12vw;
      transform: translateX(-50%);
      bottom: 5px;
      text-align: center;
      a{
        display: inline-block;
        padding: 3px;
        color: #fff;
      }
      span{
        color: #fff;
      }
    }
  }
  }


.slide-fade-enter-active {
  transition: all .3s ease;
}

</style>
